<template>
    <v-container class="panel-wrapper panel-overview pa-0" grid-list-lg fluid>
        <v-layout column>
            <v-flex>
                <TabCard title="Database Manager">
                    <v-layout column>
                        <v-flex sm12>
                            <v-btn :title="`Go to the Kibana interface to manage your database.`" class="mx-0 text-none"
                                   @click="openKibana" color="primary">
                                Launch
                            </v-btn>
                            <span class="fs-4 font-weight-medium"
                                  style="position: relative;top: 8px; display: inline-block;margin-left: 10px;color: #837878;">Manage your database with Kibana.</span>
                        </v-flex>
                        <v-flex  >
                            <small-block >
                                <template slot="title">User: {{appInfo.added_data.user}}</template>
                            </small-block>
                        </v-flex>
                        <v-flex  >
                            <small-block >
                                <template slot="title">Password: {{appInfo.added_data.password}}</template>
                            </small-block>
                        </v-flex>
                    </v-layout>
<!--                    <p class="mb-2">-->
<!--                        Kibana is a free and open frontend application that sits on top of the Elastic Stack,-->
<!--                        providing search and data visualization capabilities for data indexed in Elasticsearch.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        The Elasticsearch comes with a BASIC license and you will get access to all the basic features. Click-->
<!--                        <a style="display: inline;" href="https://www.elastic.co/subscriptions">here</a> to see more information about the features.-->
<!--                    </p>-->

                    <Help :mod="['menu_tab_kibana']" :product="appInfo.product_name"></Help>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import TabCard from '@/components/TabCard.vue';
    import SmallBlock from "@/components/SmallBlock";
    import Help from '@/components/Help'


    export default {
        name: "kibana",
        components: {SmallBlock, TabCard ,Help},
        computed: {
            ...mapState('application', ['appInfo']),
            ...mapGetters('application', ['getServiceId'])
        },
        methods: {
            openKibana() {
                window.open(this.appInfo.added_data.kibana_url,'_blank')
            },

        },
        created() {
            this.$SDK.track({pageName: 'kibana', productName: this.appInfo.product_name})
        }
    }
</script>

<style lang="scss">

</style>