var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-overview pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Database Manager" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { sm12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-0 text-none",
                              attrs: {
                                title:
                                  "Go to the Kibana interface to manage your database.",
                                color: "primary"
                              },
                              on: { click: _vm.openKibana }
                            },
                            [
                              _vm._v(
                                "\n                                Launch\n                            "
                              )
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "fs-4 font-weight-medium",
                              staticStyle: {
                                position: "relative",
                                top: "8px",
                                display: "inline-block",
                                "margin-left": "10px",
                                color: "#837878"
                              }
                            },
                            [_vm._v("Manage your database with Kibana.")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "small-block",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "User: " + _vm._s(_vm.appInfo.added_data.user)
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "small-block",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "Password: " +
                                    _vm._s(_vm.appInfo.added_data.password)
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("Help", {
                    attrs: {
                      mod: ["menu_tab_kibana"],
                      product: _vm.appInfo.product_name
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }